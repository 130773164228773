import { getImageUrl } from "@/utils/images"
import { Avatar, AvatarProps, HStack, Text } from "@chakra-ui/react"
import capitalize from "lodash.capitalize"

export const getUserName = (user?: {
  first_name?: string
  last_name?: string
  username?: string
  invited_email?: string
}) => {
  if (user?.first_name || user?.last_name) {
    return `${capitalize(user?.first_name ?? "")} ${capitalize(
      user?.last_name ?? ""
    )}`
  }
  return user?.username || user?.invited_email
}

const UserAvar = ({
  user,
  showName,
  ...props
}: { user: any; showName?: boolean } & AvatarProps) => {
  //@ts-ignore
  const pictureUrl = user?.picture?.url
  const hasAccount = user?.first_name || user?.last_name || user?.username
  const name = getUserName(user)
  return (
    <HStack>
      <Avatar
        name={name}
        size="sm"
        src={getImageUrl(pictureUrl)}
        {...props}
        bg="secondary.medium"
      />
      {showName && (
        <Text
          fontFamily="GTMaru"
          fontWeight="bold"
          fontSize="sm"
          textTransform={hasAccount ? "capitalize" : "none"}
        >
          {name}
        </Text>
      )}
    </HStack>
  )
}

export default UserAvar
